import Head from "next/head";
import { type NextPageContext } from "next";
import { getCache } from "@fwa/src/store/SwrCache";

import { pageFetcher } from "@fwa/src/services/apiClient";
import { fwsUrlTeamSlug } from "@fwa/src/services/teamService";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { getShareContentFromEntity } from "@fwa/src/utils/shareUtils";
import { queryAsString } from "@fwa/src/utils/urlUtils";

import { ErrorPageWrapper } from "@fwa/src/components/ErrorPageWrapper";
import { TeamPage } from "@fwa/src/components/TeamPage";

import {
  type TeamPageType,
  type ResponseError,
  type BrandNameType,
} from "@fwa/src/types";

type PageProps = {
  pageData?: TeamPageType;
  error?: ResponseError;
  themeName?: BrandNameType;
};

const Page = ({ pageData, themeName, error }: PageProps) => {
  const { title, description, image } = pageData
    ? getShareContentFromEntity(pageData)
    : { title: "", description: "", image: "" };

  return (
    <ErrorPageWrapper error={error}>
      {pageData && (
        <>
          <Head>
            <title>{title}</title>
            <meta name="robots" content="noindex" />
            {/* sharing meta */}
            <meta property="og:title" content={title} />
            {description?.length && (
              <>
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
              </>
            )}
            <meta property="og:url" content={pageData?.url} />
            <meta property="og:type" content="website" />
            {image && (
              <>
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:image" content={image} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
              </>
            )}
            {/* end sharing meta */}
          </Head>
          <TeamPage pageData={pageData} themeName={themeName} />
        </>
      )}
    </ErrorPageWrapper>
  );
};

Page.getInitialProps = async ({
  query,
  res,
}: NextPageContext): Promise<PageProps> => {
  if (res) {
    res.setHeader(
      "Cache-Control",
      "max-age=3600, stale-while-revalidate=86400",
    );
  }

  const { slug } = query;
  const cache = getCache();
  const slugString = queryAsString(slug);
  const url = fwsUrlTeamSlug({ slug: slugString || "" });
  const getThemeName = (page: TeamPageType) =>
    (page?.theme as BrandNameType) ||
    page?.teamLeaderPage?.fundraisingPageType?.brand?.name;

  if (isBrowser) {
    const cachedData = cache.get(url)?.data as TeamPageType;
    if (cachedData)
      return {
        pageData: cachedData,
        error: undefined,
        themeName: getThemeName(cachedData),
      };
  }

  const { pageData, error } = await pageFetcher<TeamPageType>(url);

  return { pageData, error, themeName: getThemeName(pageData!) };
};

export default Page;
